<template>
  <!-- 课程大纲 -->
  <div class="main-contain">
    <template v-if="coursechaptersinfo && coursechaptersinfo.length > 0">
      <div style="width: 100%;color: red;text-align: right;font-size: 14px;">
        <span>课程观看进度将在5分钟后更新</span>
      </div>
      <div class="item" v-for="(item0, index) in coursechaptersinfo" :key="index + 'jhkjh_' + item0.id">
        <div class="title">
          <div class="title">{{ item0.number }}</div>
          <div class="title">{{ item0.name }}</div>
        </div>
        <template v-if="item0.outline_file_list && item0.outline_file_list.length > 0">
          <div class="second-item second-item_p" v-for="(item4, index) in item0.outline_file_list"
            :key="index + '_jhjh' + item4.id ">
            <div class="itemleft" @click="lookbt(item4)">
              <el-image class="elimage" :src="file_icon(item4.ext)" />
              <div class="title">{{ item4.name }}</div>
            </div>
            <div class="itemright">
              <!-- <el-image class="elimage" :src="viewicon" /> -->
            </div>
          </div>
        </template>
        <template v-if="item0.children && item0.children.length > 0">
          <div class="item-item" v-for="(item1, index) in item0.children" :key="index">
            <div class="item-item-title">
              <div class="itemleft">
                <div class="icon"></div>
                <div class="num">{{ item1.number }}</div>
                <div class="text">{{ item1.name }}</div>
              </div>
              <div class="itemright">
                <el-progress :stroke-width="8"
                  :percentage="item1.progress ? parseInt(item1.progress * 100) : 0"></el-progress>
              </div>
            </div>
            <!-- <div  class="line"></div> -->
            <template v-if="item1.outline_file_list && item1.outline_file_list.length > 0
              ">
              <div class="line"></div>
              <div class="second-item" v-for="(item2, index) in item1.outline_file_list"
                :key="index + '_dsweds' + item2.id">
                <div class="itemleft" @click="lookbt(item2)">
                  <el-image class="elimage" :src="file_icon(item2.ext)" />
                  <div class="title">{{ item2.name }}</div>
                </div>
                <div class="itemright">
                  <!-- <el-image class="elimage" :src="viewicon" /> -->
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
    </template>
    <Customdialog ref="customdialog" class="customdialog" @isDialogVisible="openCustomdialog" :width="videoWidth"
      type="form" title="预览" :showclose="false" @isDialogClose="close_dialog">
      <div class="dialogbody" slot="dialogbody">
        <template v-if="type == '视频' && isMobile == 1">
          <video class="video_view" ref="vueRef" id="myvideo" :src="videoviewurl" controls
            controlslist="nodownload noplaybackrate" disablepictureinpicture @play="getVidDur()"
            @timeupdate="timeUpdate" @mouseover="showMarker()" @mouseout="hideMarker()"
            @contextmenu.prevent="handleRMouse" width="100%" height="500" />
          <div class="tips" v-if="isShow" @click.stop="hideExperiment()">关联实验 ></div>
          <div class="close_btn" v-else @click.stop="hideExperiment()">
            <span class="el-icon-circle-close"></span>
          </div>
          <div class="experiment_cont" v-if="flag && !isShow" @mouseover="showArrow()">
            <div class="item_cont">
              <div class="ex_item" v-for="(item, inx) in experimentList" :key="inx">
                <div class="ex_item_cont">
                  <div class="item_img">
                    <img :src="item.cover" alt />
                  </div>
                  <div class="item_explain">
                    <div class="name">
                      <el-tooltip :content="item.name" placement="top" effect="light" v-if="item.name.length > 7">
                        {{
                        item.name
                        }}
                      </el-tooltip>
                      <div class="text">{{ item.name }}</div>
                      <div class="btn">
                        <el-button type="primary" @click="tourl(item)">进入实验</el-button>
                      </div>
                    </div>
                    <div class="desc">
                      <div>参与人次: {{ item.join_count }}</div>
                      <div>
                        <el-button type="text" @click="toDetail(item.vr_id)">实验详情</el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ex_prev" v-if="!isShow && experimentList.length > 4" @click.stop="prev()">
            <span class="el-icon-arrow-left"></span>
          </div>
          <div class="ex_next" v-if="!isShow && experimentList.length > 4" @click.stop="next()">
            <span class="el-icon-arrow-right"></span>
          </div>
          <div class="marker_cont">
            <div class="marker_item_cont">
              <div class="m_item" v-for="(item, inx) in markerList" :key="inx" @click="showProblem(item)"
                :style="{ left: `${(item.time / duration) * 100}%` }">
                <div class="dot"></div>
              </div>
            </div>
          </div>
          <template v-if="!is_drag">
            <div class="tip_con" style=" background: none; border: none;">
              <div class="icon" style=" background-color: red;">!</div>
              <div class="tip_tex" style="color: red;">初次观看视频无法快进，学习完成后才能快进！</div>
            </div>
          </template>
        </template>
        <template v-if="type == '视频' && isMobile == 0">
          <video class="video_view" ref="vueRef" id="myvideo" :src="videoviewurl" controls
            controlslist="nodownload noplaybackrate" disablepictureinpicture @play="getVidDur()"
            @timeupdate="timeUpdate" @mouseover="showMarker()" @mouseout="hideMarker()"
            @contextmenu.prevent="handleRMouse" width="100%" height="300" />
          <div class="tips" v-if="isShow" @click.stop="hideExperiment()">关联实验 ></div>
          <div class="close_btn" v-else @click.stop="hideExperiment()">
            <span class="el-icon-circle-close"></span>
          </div>
          <div class="experiment_cont" v-if="flag && !isShow" @mouseover="showArrow()">
            <div class="item_cont">
              <div class="ex_item" v-for="(item, inx) in experimentList" :key="inx">
                <div class="ex_item_cont">
                  <div class="item_img">
                    <img :src="item.cover" alt />
                  </div>
                  <div class="item_explain">
                    <div class="name">
                      <el-tooltip :content="item.name" placement="top" effect="light" v-if="item.name.length > 7">
                        {{
                        item.name
                        }}
                      </el-tooltip>
                      <div class="text">{{ item.name }}</div>
                      <div class="btn">
                        <el-button type="primary" @click="tourl(item)">进入实验</el-button>
                      </div>
                    </div>
                    <div class="desc">
                      <div>参与人次: {{ item.join_count }}</div>
                      <div>
                        <el-button type="text" @click="toDetail(item.vr_id)">实验详情</el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ex_prev" v-if="!isShow && experimentList.length > 4" @click.stop="prev()">
            <span class="el-icon-arrow-left"></span>
          </div>
          <div class="ex_next" v-if="!isShow && experimentList.length > 4" @click.stop="next()">
            <span class="el-icon-arrow-right"></span>
          </div>
          <div class="marker_cont">
            <div class="marker_item_cont">
              <div class="m_item" v-for="(item, inx) in markerList" :key="inx" @click="showProblem(item)"
                :style="{ left: `${(item.time / duration) * 100}%` }">
                <div class="dot"></div>
              </div>
            </div>
          </div>
          <template v-if="!is_drag">
            <div class="tip_con" style=" background: none; border: none;">
              <div class="icon" style=" background-color: red;">!</div>
              <div class="tip_tex" style="color: red;">初次观看视频无法快进，学习完成后才能快进！</div>
            </div>
          </template>
        </template>
        <template v-if="type == '图片'">
          <!-- <el-image class="image_view" :src="imageviewurl" fit="contain" /> -->
          <iframe class="iframe_cont" :src="imageviewurl" frameborder="0" ref="myiframe"></iframe>
        </template>
        <template v-if="type == '其他'">
          <!-- <div class="tip_con">
            <div class="icon">!</div>
            <div class="tip_tex">
              该文件暂时不支持在线预览，您可以下载后查看！
            </div>
          </div>-->
          <iframe class="iframe_cont" :src="file_url" frameborder="0" ref="myiframe" width="100%"></iframe>
        </template>
        <template v-if="type == 'PDF'">
          <!-- <div class="pdf_con">
            <md-contract :url="fileurl" :visible="contractVisable" :showBtns="true" @handleModal="close">
            </md-contract>
          </div>-->
          <iframe class="iframe_cont" :src="fileurl" frameborder="0" ref="myiframe"></iframe>
        </template>
        <div id="toolbar" :class="toolbar == false ? 'tool-box doc_1' : 'tool-box'" style="visibility: visible;">
          <div class="line-bar1"></div>
          <div class="full-box">
            <a class="full-btn1" @click="screen">
              <i class="el-icon-full-screen" style="font-size:17px"></i>
              <span style="margin-left:4px">全屏</span>
            </a>
            <!-- <a class="full-btn2" @click="fullScreen" style="display:none">
              <i class="iconfont"></i><span style="margin-left:4px">退出全屏</span>
            </a>-->
          </div>
        </div>
      </div>
      <div slot="dialogfooter" class="dialogfooter">
        <el-button type="primary" @click="download()" v-if="!is_download">下载</el-button>
        <el-button type="info" @click="close_down">关闭</el-button>
      </div>
    </Customdialog>

    <el-dialog ref="customInner" class="customInner" width="34%" title="习题" :visible.sync="innerVisible"
      @close="closeProblem" @open="openProblem" append-to-body>
      <div class="dialogbody">
        <div class="list_cont">
          <div>题号:</div>
          <div class="list_item" :class="item.check ? 'active' : ''" v-for="(item, inx) in setsList" :key="inx"
            @click="changeList(item, inx)">{{ inx + 1 }}</div>
        </div>
        <div class="list_exercises">
          <TabsProblem v-if="problem" :info="info" @checkMsg="checkMsg"></TabsProblem>
        </div>
      </div>
      <div class="dialogfooter">
        <el-button type="primary" @click="prevProblem" v-if="setsIndex">上一题</el-button>
        <el-button type="primary" @click="nextProblem">下一题</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// import axios from "axios";
import Customdialog from "@/components/customdialog.vue"
import { getoutlinelist, currentProgress, getOutlineTimelist, getTimeVrList } from "@/api/outline"
import mdContract from "@/contract.md.vue"
import { getToken } from "@/utils/auth"
import { getvrmodulelink, getCourseVrModuleList, getVRList } from "@/api/vr"
import TabsProblem from "./tabsProblem.vue"
import { Base64 } from 'js-base64'
import screenfull from 'screenfull'
const request_base_path = require('@/utils/base_url')
export default {
  name: "courseoutline",
  components: {
    Customdialog,
    mdContract,
    TabsProblem
  },
  data () {
    return {
      filePreview: request_base_path.pro_url + 'file-preview/onlinePreview?url=', // 数据预览
      picturesPreview: request_base_path.pro_url + 'file-preview/picturesPreview?urls=', // 多图预览
      fileTask: request_base_path.pro_url + 'file-preview/addTask?url=', // 数据预埋

      token: getToken() ? true : false,
      fileurl: "",
      file_url: "",
      type: "",
      videoviewurl: "",
      imageviewurl: "",
      coursechaptersinfo: "",
      imageviewurlList: [], // 图片数据
      isImg: ["jpg", "png", "jpeg", "gif", "webp"],
      viewicon: require("@/assets/查看.png"),
      icon1: require("@/assets/coursedetails/文件类型-标准图-视频文件.png"),
      icon2: require("@/assets/coursedetails/文件类型-标准图-Word文档.png"),
      icon3: require("@/assets/coursedetails/文件类型-标准图-幻灯片.png"),
      icon4: require("@/assets/coursedetails/文件类型-标准图-声音文件.png"),
      icon5: require("@/assets/coursedetails/文件类型-标准图-图片文件.png"),
      fileicon1: require("@/assets/fileicon/files.png"),
      fileicon2: require("@/assets/fileicon/icon-PDF.png"),
      fileicon3: require("@/assets/fileicon/icon-word.png"),
      fileicon4: require("@/assets/fileicon/icon-video.png"),
      fileicon5: require("@/assets/fileicon/icon-img.png"),
      fileicon6: require("@/assets/fileicon/icon-node.png"),
      fileicon7: require("@/assets/fileicon/icon-audio.png"),
      fileicon8: require("@/assets/fileicon/icon-chapter.png"),
      fileicon9: require("@/assets/fileicon/rar.png"),
      fileicon10: require("@/assets/fileicon/single.png"),
      fileicon11: require("@/assets/fileicon/txt.png"),
      fileicon12: require("@/assets/fileicon/xlsx.png"),
      fileicon13: require("@/assets/fileicon/zip.png"),
      outline_file_id: "",
      course_sn: "",
      outline_file_len: "",
      progress: "",
      is_download: 0,
      itemObj: {},
      isShow: false,
      experimentList: [],
      currentIndex: 0,
      speedW: 0,
      player: null,
      markerList: [],
      duration: 0,
      innerVisible: false,
      setsList: [],
      setsIndex: null,
      // id: null,
      info: {},
      obj: [],
      currentTime: 0,
      noFirstPlay: false,  // 是否第一次播放
      checkMsgData: false,
      experTotal: [],
      flag: false,
      maxTime: 0,
      problem: false,
      fullscreen: false, //全屏
      toolbar: false,
      is_drag: 0,
      learn_time: 0,
      videoWidth: '',
      isMobile: '', // 判断手机端字段
    }
  },
  filters: {},
  watch: {
    setsIndex: {
      handler (val) {
        if (val === null) return false
        this.info = this.obj.filter((item, index) => {
          if (index === val) {
            return item
          }
        })
      },
      immediate: true,
      deep: true,
    },
    currentTime: {
      handler (val) {
        if (!val) return false
        //console.log('currentTime-----', val)
        this.markerList.forEach(item => {
          if (item.time === Math.trunc(val)) {
            if (item.time === Math.round(val)) {
              this.player.pause()
              this.player.currentTime = val + 0.5  // 自动快进1s防止习题时间点重复触发
              this.showProblem(item)
            }
          }
        })

        this.experTotal.forEach(item => {
          if (item.time === Math.trunc(val)) {
            if (item.time === Math.round(val)) {
              item.vr_list.forEach(el => {
                this.experimentList.push(el)
              })
              this.flag = true
              this.player.pause()
              this.player.currentTime = val + 0.5  // 自动快进1s防止实验时间点重复触发
              //console.log('this.experimentList----', this.experimentList)
            }
          }
        })
      },
      immediate: true,
      deep: true,
    },
    experimentList: {
      handler (val) {
        this.$nextTick(() => {
          let htmlW = document.documentElement.clientWidth
          if (!val.length) return false
          let t_dis = document.querySelector('.experiment_cont')
          let t_w = t_dis.offsetWidth
          let dis = document.querySelector('.item_cont')
          let disArr = document.querySelectorAll('.ex_item')

          if (htmlW < 1900) {
            this.speedW = `${(t_w - 24) / 3}`
            disArr.forEach(item => {
              item.style['width'] = `${(t_w - 24) / 3}px`
            })
          } else {
            this.speedW = `${(t_w - 34) / 4}`
            disArr.forEach(item => {
              item.style['width'] = `${(t_w - 34) / 4}px`
            })
          }
          let is_w = disArr[0].offsetWidth
          dis.style['width'] = `${is_w * disArr.length + (disArr.length + 1) * 10}px`
        })
      },
      immediate: true,
      deep: true,
    }
  },
  beforeDestroy () {
    if (this.player === null) return false
    else {
      this.player.pause()
    }
  },
  created () {
    this.course_sn = decodeURIComponent(this.$route.query.course_sn)
    this.getoutlinelist({
      course_sn: this.course_sn,
      is_show_progress: 1,
    })
    if (this._isMobile()) {
      this.isMobile = 0
      this.videoWidth = 100 + "%"
    } else {
      this.isMobile = 1
      this.videoWidth = 70 + "%"
    }
    // this.getVR()
  },
  methods: {
    // 判断手机端
    _isMobile () {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    },
    handleRMouse (e) {
      e.preventDefault()
    },
    async updateTimeVrList (data) {
      let params = {
        time: null,
        outline_file_id: data
      }
      await getTimeVrList(params).then(res => {
        this.experTotal = res.data
      }).catch(error => { })
    },
    getVR () {
      getVRList({ course_sn: this.course_sn }).then(res => {
        this.experimentList = res.data.data
        this.getCourseVr()
      }).catch(error => { })
    },
    getCourseVr () {
      getCourseVrModuleList({ course_sn: this.course_sn }).then(res => {
        let resArr = res.data
        resArr.forEach(item => {
          this.experimentList.forEach(el => {
            if (el.name === item.name) {
              el.vr_id = item.id
            }
          })
        })
      }).catch(err => { })
    },
    openCustomdialog (val) {
      if (this.type != "视频") {
        return false
      }
      let that = this
      that.$nextTick(() => {
        that.player = document.getElementById("myvideo")
        that.player.addEventListener("loadedmetadata", function () {
          that.duration = parseInt(this.duration)     //获取总时长
          let resCurrent = that.learn_time  // Math.floor(that.duration * that.progress * 100) / 100 // 临时放开禁止视频快进
          that.currentTime = resCurrent
          that.player.currentTime = resCurrent
        })
      })
    },
    timeUpdate (e) {
      if (!this.is_drag) { // is_drag: 0, 不可拖拽 1, 可拖拽
        if (this.progress === 1) {
          return false
        } else {
          // 不可拖拽逻辑
          if (e.srcElement.currentTime - this.currentTime > 1) {
            e.srcElement.currentTime = this.currentTime > this.maxTime ? this.currentTime : this.maxTime
            //console.log("快进了")
          }
          this.currentTime = e.srcElement.currentTime
          this.maxTime = this.currentTime > this.maxTime ? this.currentTime : this.maxTime
        }
      }
    },
    //视频进度历史
    getVidDur () {
      if (this.progress === 1) {
        setTimeout(() => {
          if (this.player.currentTime > this.currentTime) {
            this.player.currentTime = 0
          }
          return false
        }, 150)
      } else {
        if (this.noFirstPlay) { // 不是第一播放
          if (!this.is_drag) { // is_drag: 0, 不可拖拽 1, 可拖拽
            this.player.currentTime = this.currentTime
          }
        }
        // else { // 第一播放，自动跳转到上次播放时间
        //   if (this.progress >= 0) {
        //     let num = this.player.duration * this.progress ? this.player.duration * this.progress : this.currentTime
        //     this.player.currentTime = Math.floor(num * 100) / 100
        //   }
        // }
      }
      this.noFirstPlay = true
    },
    initdata () {
      this.getoutlinelist({
        course_sn: this.course_sn,
        is_show_progress: 1,
      })
    },
    file_icon (val) {
      switch (val) {
        case "file":
          return this.fileicon1

        case "pdf":
          return this.fileicon2

        case ("word", "docx"):
          return this.fileicon3

        case "mp4":
          return this.fileicon4

        case ("png", "jpg"):
          return this.fileicon5

        case "radio":
          return this.fileicon7

        case "rar":
          return this.fileicon9

        case "txt":
          return this.fileicon11

        case "xlsx":
          return this.fileicon12

        case "zip":
          return this.fileicon13

        default:
          return this.fileicon10
      }
    },
    async lookbt (item) {
      this.is_drag = item.is_drag
      this.resetFill()
      await this.getTask(item.url)
      if (item.progress !== null || item.progress !== '') {
        this.progress = Number(item.progress)
      } else {
        this.progress = 0
      }
      if (item.learn_time) {
        this.learn_time = Number(item.learn_time)
      }
      this.outline_file_id = item.id
      this.outline_file_len = item.length
      if (item.ext == 'mp4' || item.ext == 'avi' || item.ext == 'flv') {
        await this.updateMarker(item.id)
        await this.updateTimeVrList(item.id)
        this.type = "视频"
        this.videoviewurl = item.url
        this.toolbar = false
      } else if (item.ext == "png" || item.ext == "jpg") {
        this.type = "图片"
        // this.imageviewurl = item.url
        let len = this.imageviewurlList.length
        for (let i = 0; i < this.imageviewurlList.length; i++) {
          if (this.imageviewurlList[i] == item.url) {
            let newArr = this.imageviewurlList.splice(i, len - i)
            let oldArr = this.imageviewurlList
            this.imageviewurlList = [...newArr, ...oldArr]
            break
          }
        }
        let url = this.imageviewurlList.join("|")
        this.imageviewurl = this.picturesPreview + encodeURIComponent(Base64.encode(url))
        this.toolbar = true
      } else if (item.ext == "pdf") {
        this.type = "PDF"
        this.fileurl = this.filePreview + encodeURIComponent(Base64.encode(item.url))
        this.toolbar = true
      } else {
        this.type = "其他"
        this.file_url = this.filePreview + encodeURIComponent(Base64.encode(item.url))
        this.toolbar = true
      }
      this.itemObj = item
      this.is_download = item.is_download
      this.$refs.customdialog.dialogopenbt()
    },
    resetFill () {
      this.imageviewurl = ''
      this.fileurl = ''
      this.file_url = ''
    },
    getTask (data) {
      this.fileTask + data
    },
    // 下载
    download () {
      let row = this.itemObj
      //1.先创建一个a标签
      let a = document.createElement("a")
      a.target = '_bank'
      a.download = row.name
      //2.给a标签的href赋上下载地址
      a.href = row.url
      //3.让a标签不显示在视图中
      a.style.display = "none"
      //4.将a标签append到文档中
      document.body.appendChild(a)
      //5.a标签自点击
      a.click()
      //6.点击下载后，销毁这个节点
      document.body.removeChild(a)
    },
    resetVideo () {
      this.learn_time = 0
      this.currentTime = 0
      this.maxTime = 0
      if (this.player?.currentTime) {
        this.player.currentTime = 0
      }
      this.player = null
    },
    close_dialog () {
      this.resetFill()
    },
    close_down () {
      this.flag = false
      this.experimentList = []
      this.noFirstPlay = false
      this.$refs.customdialog.dialogclosebt()
      let videoTime = document.getElementById("myvideo")
      if (this.videoviewurl !== '') {
        if (videoTime.currentTime) {
          this.$refs.vueRef.pause() //暂停
          let params = {
            outline_file_id: this.outline_file_id,
            time: videoTime.currentTime,
          }
          this.setCurrentProgress(params)
        }
        this.resetVideo()
      } else {
        if (this.outline_file_len) {
          let params = {
            outline_file_id: this.outline_file_id
          }
          if (this.progress === "" || this.progress < 1) { //未打开过小节(未读)或未完成100%
            params.time = this.outline_file_len
            this.setCurrentProgress(params)
          }
        }
      }
      this.videoviewurl = ""
      setTimeout(() => {
        this.itemObj = {}
        this.is_download = 0
      }, 500)
    },
    getoutlinelist (params) {
      getoutlinelist(params)
        .then((response) => {
          this.coursechaptersinfo = response.data
          response.data.forEach((item) => {
            item.children.forEach((el) => {
              el.outline_file_list.forEach((items) => {
                if (this.isImg.includes(items.ext)) {
                  this.imageviewurlList.push(items.url)
                }
              })

            })
          })

        })
        .catch((error) => {
          //console.log(error)
        })
    },
    async setCurrentProgress (data) {
      await currentProgress(data).then(res => {
        if (res.code === 0) {
          // this.$message.success('进度上传成功!')
          this.initdata()
        }
      }).catch(error => {
        //console.log(error)
      })
    },
    hideExperiment () {
      this.isShow = !this.isShow
    },
    showArrow () {
      if (this.experimentList.length < 4) return false
      let dis_prev = document.querySelector('.ex_prev')
      let dis_next = document.querySelector('.ex_next')
      dis_prev.style['display'] = 'block'
      dis_next.style['display'] = 'block'
      setTimeout(() => {
        dis_prev.style['display'] = 'none'
        dis_next.style['display'] = 'none'
      }, 5000)
    },
    prev () {
      //console.log('this.currentIndex', this.currentIndex)
      if (!this.currentIndex) {
        this.$message.warning('这是第一组实验了!')
        return false
      }
      this.currentIndex--
      let dis = document.querySelector('.item_cont')
      if (!this.currentIndex) {
        dis.style['transform'] = `translateX(0px)`
      } else {
        let res = -(this.speedW * this.currentIndex + this.currentIndex * 10)
        dis.style['transform'] = `translateX(${res}px)`
      }
      //console.log('this.currentIndex--', this.currentIndex)
    },
    next () {
      //console.log('this.currentIndex', this.currentIndex)
      let len = this.experimentList.length - 1
      if (this.currentIndex === len) {
        this.$message.warning('这是最后一组实验了!')
        return false
      }
      this.currentIndex++
      let dis = document.querySelector('.item_cont')
      dis.style['transform'] = `translateX(-${this.speedW * this.currentIndex + this.currentIndex * 10}px)`
      //console.log('this.currentIndex++', this.currentIndex)
    },
    toDetail (id) {
      this.player.pause()
      let path = "/home/virtualsimulationexperiment/experimentdetails"
      window.open(request_base_path.pro_url + '#' + path + `?id=${id}`, "_blank")
    },
    tourl (item) {
      this.player.pause()
      let params = { id: item.vr_module_id }
      getvrmodulelink(params).then((response) => {
        if (response.data) {
          window.open(response.data.url, "_blank")
        }
      }).catch(() => { })
    },
    showProblem (item) {
      this.setsList = []
      if (item.stem_list.length) {
        item.stem_list.forEach(el => {
          this.setsList.push({
            id: el.stem_id,
            check: false
          })
          this.setsList[0].check = true
        })
      }
      this.obj = item.stem_list
      this.innerVisible = true
    },
    changeList (data, inx) {
      this.setsIndex = inx
      this.id = data.id
      this.setsList.forEach(item => {
        item.check = false
      })
      data.check = true
    },
    checkMsg (data) {
      this.checkMsgData = data
    },
    prevProblem () {
      // if (!this.checkMsgData) {
      //   this.$message.warning('请选择选项!')
      //   return false
      // }
      this.setsIndex--
      let arr = this.setsList
      arr.forEach((item, index) => {
        item.check = false
        if (this.setsIndex === index) {
          item.check = true
        }
      })
      // this.checkMsgData = false
    },
    nextProblem () {
      // if (!this.checkMsgData) {
      //   this.$message.warning('请选择选项!')
      //   return false
      // }
      this.setsIndex++
      let arr = this.setsList
      arr.forEach((item, index) => {
        item.check = false
        if (this.setsIndex === index) {
          item.check = true
        }
      })
      // this.checkMsgData = false
      if (this.setsIndex === arr.length) {
        this.innerVisible = false
      }
    },
    openProblem () {
      this.problem = true
      this.setsIndex = 0
      this.setsList.forEach((item, index) => {
        item.check = false
        if (this.setsIndex === index) {
          item.check = true
        }
      })
    },
    closeProblem () {
      this.problem = false
      this.setsIndex = null
      this.player.play()
    },
    showMarker () {
      return false
    },
    hideMarker () {
      return false
    },
    async updateMarker (id) {
      await getOutlineTimelist({ outline_file_id: id }).then(res => {
        this.markerList = res.data
      }).catch(error => { })
    },

    //全屏
    screen () {
      if (screenfull.isEnabled) {
        screenfull.toggle(this.$refs.myiframe)
      }
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 640px) {
  .main-contain {
    border: 1px solid transparent;
    margin-top: 10px;
    width: 95%;
    margin: 0 auto;

    // height: 100%;
    .item {
      // border: 1px solid red;
      margin-top: 20px;
      // margin-left: 30px;

      .title {
        //  border: 1px solid red;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .title {
          margin-right: 20px;
          font-size: 18px;
          font-weight: bold;
          color: #333333;
        }
      }

      .second-item_p {
        padding-left: 20px;
        padding-right: 20px;
      }

      .second-item {
        // border: 1px solid red;
        box-sizing: border-box;
        // padding-left: 40px;
        // padding-right: 40px;
        margin-top: 10px;
        margin-left: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .itemleft {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;

          .elimage {
            width: 20px;
            height: 20px;
          }

          .title {
            margin-left: 10px;
            font-size: 15px;

            font-weight: 400;
            color: #666666;
          }
        }

        .itemright {
          font-size: 15px;
          font-weight: 400;
          color: #3d84ff;
          cursor: pointer;
          user-select: none;
        }
      }

      .item-item {
        margin-top: 10px;
        // margin-left: 70px;
        // width: 1126px;
        // height: 118px;
        background: #f9f9f9;
        box-sizing: border-box;
        padding: 20px;

        .item-item-title {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .itemleft {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .icon {
              width: 6px;
              height: 6px;
              background: #3d84ff;
              border-radius: 50%;
            }

            .num {
              margin-left: 10px;
              margin-right: 10px;
              font-size: 18px;

              font-weight: 400;
              color: #666666;
            }

            .text {
              font-size: 14px;
              font-weight: 400;
              color: #666666;
            }
          }

          .itemright {
            // border: 1px solid red;
            width: 150px;
            text-align: right;

            // display: flex;
            // justify-content: space-between;
            // align-items: center;
            .text {
              margin-left: 4px;
              font-size: 15px;

              font-weight: 400;
              color: #3d84ff;
            }
          }
        }

        .line {
          margin-left: 20px;
          margin-top: 10px;
          border-bottom: 1px solid #ebeef5;
        }

        .second-item {
          margin-top: 10px;
          margin-left: 40px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .itemleft {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .elimage {
              width: 20px;
              height: 20px;
            }

            .title {
              margin-left: 10px;
              font-size: 15px;
              font-weight: 400;
              color: #666666;
            }
          }

          .itemright {
            font-size: 15px;
            font-weight: 400;
            color: #3d84ff;
            cursor: pointer;
            user-select: none;
          }
        }
      }
    }

    .customdialog {
      .dialogbody {
        // border: 1px solid red;
        padding: 10px;

        .video_view {
        }

        .image_view {
          height: 600px;
          width: 100%;
        }

        .pdf_con {
          // border: 1px solid red;
          position: relative;
        }

        .tip_con {
          height: 60px;
          background: #e6f7ff;
          border: 1px solid #91d5ff;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;

          .icon {
            margin-right: 10px;
            width: 20px;
            height: 20px;
            line-height: 17px;
            text-align: center;
            border-radius: 50%;
            background: #1890ff;
            color: white;
          }

          .tip_tex {
            font-size: 14px;
            font-weight: bold;
            color: #999999;
          }
        }
      }

      .dialogfooter {
        // border: 1px solid red;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  ::v-deep .dialog-body {
    min-height: 370px !important;
  }
}

@media screen and (min-width: 640px) and (max-width: 1200px) {
  .main-contain {
    border: 1px solid transparent;
    margin-top: 10px;
    width: 95%;
    margin: 0 auto;

    // height: 100%;
    .item {
      // border: 1px solid red;
      margin-top: 20px;
      // margin-left: 30px;

      .title {
        //  border: 1px solid red;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .title {
          margin-right: 20px;
          font-size: 18px;
          font-weight: bold;
          color: #333333;
        }
      }

      .second-item_p {
        padding-left: 20px;
        padding-right: 20px;
      }

      .second-item {
        // border: 1px solid red;
        box-sizing: border-box;
        // padding-left: 40px;
        // padding-right: 40px;
        margin-top: 10px;
        margin-left: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .itemleft {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;

          .elimage {
            width: 20px;
            height: 20px;
          }

          .title {
            margin-left: 10px;
            font-size: 15px;

            font-weight: 400;
            color: #666666;
          }
        }

        .itemright {
          font-size: 15px;
          font-weight: 400;
          color: #3d84ff;
          cursor: pointer;
          user-select: none;
        }
      }

      .item-item {
        margin-top: 10px;
        // margin-left: 70px;
        // width: 1126px;
        // height: 118px;
        background: #f9f9f9;
        box-sizing: border-box;
        padding: 20px;

        .item-item-title {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .itemleft {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .icon {
              width: 6px;
              height: 6px;
              background: #3d84ff;
              border-radius: 50%;
            }

            .num {
              margin-left: 10px;
              margin-right: 10px;
              font-size: 18px;

              font-weight: 400;
              color: #666666;
            }

            .text {
              font-size: 14px;
              font-weight: 400;
              color: #666666;
            }
          }

          .itemright {
            // border: 1px solid red;
            width: 150px;
            text-align: right;

            // display: flex;
            // justify-content: space-between;
            // align-items: center;
            .text {
              margin-left: 4px;
              font-size: 15px;

              font-weight: 400;
              color: #3d84ff;
            }
          }
        }

        .line {
          margin-left: 20px;
          margin-top: 10px;
          border-bottom: 1px solid #ebeef5;
        }

        .second-item {
          margin-top: 10px;
          margin-left: 40px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .itemleft {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .elimage {
              width: 20px;
              height: 20px;
            }

            .title {
              margin-left: 10px;
              font-size: 15px;
              font-weight: 400;
              color: #666666;
            }
          }

          .itemright {
            font-size: 15px;
            font-weight: 400;
            color: #3d84ff;
            cursor: pointer;
            user-select: none;
          }
        }
      }
    }

    .customdialog {
      .dialogbody {
        // border: 1px solid red;
        padding: 10px;

        .video_view {
        }

        .image_view {
          height: 600px;
          width: 100%;
        }

        .pdf_con {
          // border: 1px solid red;
          position: relative;
        }

        .tip_con {
          height: 60px;
          background: #e6f7ff;
          border: 1px solid #91d5ff;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;

          .icon {
            margin-right: 10px;
            width: 20px;
            height: 20px;
            line-height: 17px;
            text-align: center;
            border-radius: 50%;
            background: #1890ff;
            color: white;
          }

          .tip_tex {
            font-size: 14px;
            font-weight: bold;
            color: #999999;
          }
        }
      }

      .dialogfooter {
        // border: 1px solid red;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  ::v-deep .dialog-body {
    min-height: 370px !important;
  }
}

@media screen and (min-width: 1200px) {
  .main-contain {
    border: 1px solid transparent;
    margin-top: 10px;

    // height: 100%;
    .item {
      // border: 1px solid red;
      margin-top: 20px;
      margin-left: 30px;

      .title {
        //  border: 1px solid red;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .title {
          margin-right: 20px;
          font-size: 18px;
          font-weight: bold;
          color: #333333;
        }
      }

      .second-item_p {
        padding-left: 20px;
        padding-right: 20px;
      }

      .second-item {
        // border: 1px solid red;
        box-sizing: border-box;
        // padding-left: 40px;
        // padding-right: 40px;
        margin-top: 10px;
        margin-left: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .itemleft {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;

          .elimage {
            width: 20px;
            height: 20px;
          }

          .title {
            margin-left: 10px;
            font-size: 15px;

            font-weight: 400;
            color: #666666;
          }
        }

        .itemright {
          font-size: 15px;
          font-weight: 400;
          color: #3d84ff;
          cursor: pointer;
          user-select: none;
        }
      }

      .item-item {
        margin-top: 10px;
        margin-left: 70px;
        // width: 1126px;
        // height: 118px;
        background: #f9f9f9;
        box-sizing: border-box;
        padding: 20px;

        .item-item-title {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .itemleft {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .icon {
              width: 6px;
              height: 6px;
              background: #3d84ff;
              border-radius: 50%;
            }

            .num {
              margin-left: 10px;
              margin-right: 10px;
              font-size: 18px;

              font-weight: 400;
              color: #666666;
            }

            .text {
              font-size: 18px;

              font-weight: 400;
              color: #666666;
            }
          }

          .itemright {
            // border: 1px solid red;
            width: 150px;
            text-align: right;

            // display: flex;
            // justify-content: space-between;
            // align-items: center;
            .text {
              margin-left: 4px;
              font-size: 15px;

              font-weight: 400;
              color: #3d84ff;
            }
          }
        }

        .line {
          margin-left: 20px;
          margin-top: 10px;
          border-bottom: 1px solid #ebeef5;
        }

        .second-item {
          margin-top: 10px;
          margin-left: 40px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .itemleft {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .elimage {
              width: 20px;
              height: 20px;
            }

            .title {
              margin-left: 10px;
              font-size: 15px;
              font-weight: 400;
              color: #666666;
            }
          }

          .itemright {
            font-size: 15px;
            font-weight: 400;
            color: #3d84ff;
            cursor: pointer;
            user-select: none;
          }
        }
      }
    }

    .customdialog {
      .dialogbody {
        // border: 1px solid red;
        padding: 10px;

        .video_view {
        }

        .image_view {
          height: 600px;
          width: 100%;
        }

        .pdf_con {
          // border: 1px solid red;
          position: relative;
        }

        .tip_con {
          height: 60px;
          background: #e6f7ff;
          border: 1px solid #91d5ff;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;

          .icon {
            margin-right: 10px;
            width: 20px;
            height: 20px;
            line-height: 17px;
            text-align: center;
            border-radius: 50%;
            background: #1890ff;
            color: white;
          }

          .tip_tex {
            font-size: 20px;
            font-weight: bold;
            color: #999999;
          }
        }
      }

      .dialogfooter {
        // border: 1px solid red;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.toolbar {
  padding: 20px 0px 10px 0px;
}

.full-box {
  display: flex;
  justify-content: flex-end;
}
</style>
